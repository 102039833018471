<!-- 401 -->
<template>
  <div class="error_401">
    <div class="container abs_center">
      <img class="err-img" src="../../assets/error-page/401.png" alt="401">
      <p class="status">
        <span>401</span>
        亲，没授权哦~
      </p>
      <p class="tips">对不起，您无此访问权限！</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },

  components: {},

  computed: {},

  mounted () {},

  methods: {}
}

</script>
<style lang='scss' scoped>
.error_401{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  .container{
    user-select: none;
    .err-img{
      width: 287px;
      height: 286px;
    }
    .status{
      text-align: center;
      color: #2D363E;
      margin-top: 45px;
      font-size: 24px;
      span{
        font-size: 72px;
        font-weight: bold;
      }
    }
    .tips{
      text-align: center;
      color: #2D363E;
      font-size: 18px;
    }
  }
}
</style>
